import React from "react";
import lang from "../lang/lang";

const ErpAction = () => {
  return (
    <section className="erp_action_area">
      <div className="container">
        <div className="row align-items-center" style={{justifyContent:"center"}}>
          <div className="erp_content">
            <h2 style={{color:"black"}}>{lang.H_HOME1}</h2>
            <div className="widget sidebar_widget widget_search mt-4">
              <form action="#" className="search-form input-group">
                <input style={{height:"50px", marginTop:"2px"}}
                  type="search"
                  className="form-control widget_input"
                  placeholder={lang.H_INPUTF}
                />
                <button type="submit" className="hosting_btn">{lang.H_SUBSCRIBE}</button>
               
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ErpAction;
