import React from "react";
import Sectitle from "../Title/Sectitle";
import Teamitem from "../Team/Teamitem";
import lang from "../../lang/lang";
const SessiondataBookDetail = () => {
  return (
    <section className="experts_team_area sec_pad" style={{marginTop:"50px"}}>
      <div className="container">
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title={lang.S_SESSION}
          tClass="t_color3"
          TitleP=""
        />
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="HealingSession.jpg"
              memberN={lang.Healing}
              memberd={75+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="Menifistation.jpg"
              memberN={lang.Menifistation}
              memberd={75+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="Planning.jpg"
              memberN={lang.Planning}
              memberd={735+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="SelfLovePackage.jpg"
              memberN={lang.SelfLovePackage}
              memberd={225+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="Coaching.jpg"
              memberN={lang.Coaching}
              memberd={75+ lang.P_Price}
            />
          </div>
          {/* <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="ChakraBalancing.jpg"
              memberN="Chakra Balancing"
              memberd={lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="HealingRelationshipsMother.jpg"
              memberN="Healing Relationships With Mother
"
              memberd={lang.P_Price}
            />
          </div> */}
          {/* <div className="col-lg-4 col-sm-6">
                        <Teamitem teamImage="team_8.jpg" memberN="Bailey Wonger" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Max Conversion" memberd="Social Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_10.jpg" memberN="Jake Weary" memberd="UI/UX designer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Justin Case" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_12.jpg" memberN="Norman Gordon" memberd="Web developer"/>
                    </div> */}
        </div>
      </div>
    </section>
  );
};
export default SessiondataBookDetail;
