import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Sticky from "react-stickynode";
import lang from "../lang/lang";
import '../../src/App.css';

class CustomNavbar extends Component {
  render() {
    var { mClass, nClass, cClass, slogo, hbtnClass } = this.props;
    return (
      <div top={0} innerZ={9999} activeClass="navbar_fixed">
        <header className="header_area">
          <div
            className="Topheader mt-4"
            
          >
            <div style={{display:"flex", flexWrap:"wrap", columnGap:"10px"}}>
              <NavLink to="/SignIn">
                <img src={require("../img/home4/Login.png")} alt="" />
              </NavLink>
              <NavLink
                to="#"
                onClick={() => {
                  localStorage.setItem(
                    "lang",
                    localStorage.getItem("lang") == "ENGLISH"
                      ? "ARABIC"
                      : "ENGLISH"
                  );
                  window.location.reload();
                }}
              >
                {localStorage.getItem("lang") == "ENGLISH" ? (
                  <div onClick={() => localStorage.setItem("lang", "ENGLISH")}>
                    <img src={require("../img/home4/Arabic.png")} alt="" />
                  </div>
                ) : (
                  <div onClick={() => localStorage.setItem("lang", "ARABIC")}>
                    <img src={require("../img/home4/English.png")} alt="" />
                  </div>
                )}
              </NavLink>
            </div>
          </div>
          <div className="container" style={{ textAlign: "center" }}>
            <Link className={`navbar-brand ${slogo}`} to="/">
              <img src={require("../img/AmaniHeaderLogo.png")} />
              <img src={require("../img/AmaniHeaderLogo.png")} alt="" />
            </Link>
          </div>
          <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
            <div className={`container ${cClass}`}>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="menu_toggle">
                  <span className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                  <span className="hamburger-cross">
                    <span></span>
                    <span></span>
                  </span>
                </span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                  {/* <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                    <Link
                      to="./"
                      className="nav-link dropdown-toggle"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Home
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <NavLink title="Home" className="nav-link" to="/">
                      {lang.HOME}
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    {/* <Link
                      to="./About"
                      title="Pages"
                      
                      
                    >
                    
                      About
                    </Link> */}
                    <NavLink title="About" className="nav-link" to="./About">
                      {lang.ABOUT}
                    </NavLink>
                    {/* <ul role="menu" className=" dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Service"
                          className="nav-link"
                          to="/Service"
                        >
                          Service
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Service Details"
                          className="nav-link"
                          to="/ServiceDetails"
                        >
                          Service Details
                        </NavLink>
                      </li>
                    </ul> */}
                  </li>
                  {/* <li className="dropdown submenu nav-item">
                    <Link
                      to="./"
                      title="Pages"
                      className="dropdown-toggle nav-link"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Pages
                    </Link>
                    <ul role="menu" className=" dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="About"
                          className="nav-link"
                          to="/About"
                        >
                          About
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Process"
                          className="nav-link"
                          to="/Process"
                        >
                          Process
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Team"
                          className="nav-link"
                          to="/Team"
                        >
                          Team
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Price"
                          className="nav-link"
                          to="/Price"
                        >
                          Price
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Faq"
                          className="nav-link"
                          to="/Faq"
                        >
                          Faq
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="SignIn"
                          className="nav-link"
                          to="/SignIn"
                        >
                          Sign In
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="SignUp"
                          className="nav-link"
                          to="/SignUp"
                        >
                          Sign Up
                        </NavLink>
                      </li>
                    </ul>
                  </li> */}
                  <li className="dropdown submenu nav-item">
                    <Link
                      title="SERVICES"
                      className="dropdown-toggle nav-link"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      to="#"
                    >
                      {lang.SERVICES}
                    </Link>
                    <ul role="menu" className=" dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          title="Coures"
                          className="nav-link"
                          to="/CouresBook"
                        >
                          {lang.S_COURSE}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          title="Medition"
                          className="nav-link"
                          to="/MeditationdataBook"
                        >
                          {lang.S_MEDITATION}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          title="Sessiond"
                          className="nav-link"
                          to="/SessiondataBook"
                        >
                          {lang.S_SESSION}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          title="Ebook"
                          className="nav-link"
                          to="/EbookdataBook"
                        >
                          {lang.S_EBOOK}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          title="Articles"
                          className="nav-link"
                          to="/ArticlesdataBook"
                        >
                          {lang.S_ARTICLES}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          title="Podast"
                          className="nav-link"
                          to="/PodastdataBook"
                        >
                          {lang.S_POSCAST}
                        </NavLink>
                      </li>

                      {/* <li className="nav-item">
                        <NavLink
                          title="Trail"
                          className="nav-link"
                          to="/Trail"
                        >
                          Portfolio Single
                        </NavLink>
                      </li> */}
                    </ul>
                  </li>
                  {/* <li className="nav-item dropdown submenu"> */}
                  {/* <a
                      className="nav-link dropdown-toggle"
                      href=".#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Blog
                    </a> */}
                  {/* <ul className="dropdown-menu"> */}
                  {/* <li className="nav-item">
                    <NavLink to="/Price" className="nav-link">
                      Pricing
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                        <NavLink to="/BlogGridPage" className="nav-link">
                          Blog Grid
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/BlogSingle" className="nav-link">
                          Blog Single
                        </NavLink>
                      </li>
                    </ul>
                  </li> */}

                  <li className="nav-item">
                    <NavLink title="Contact" className="nav-link" to="/Contact">
                      {lang.CONTACT}
                    </NavLink>
                  </li>

                  {/* <li className="nav-item">
                    <NavLink title="Trail" className="nav-link" to="/Trail">
                      Trail
                    </NavLink>
                  </li> */}
                </ul>
                {/* <a
                  className={`btn_get btn_hover ${hbtnClass}`}
                  href="https://login.revooerp.com" target="_blank"
                  style={{ marginLeft: "10px" }}
                >
                  SIGN IN
                </a> */}
                {/* <NavLink
                  className={`btn_get btn_hover ${hbtnClass}`}
                  to="/SignUp"
                  style={{ marginLeft: "10px" }}
                >
                  BUY NOW!
                </NavLink> */}
              </div>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

export default CustomNavbar;
