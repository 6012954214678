import React from "react";
import Sectitle from "../Title/Sectitle";
import Teamitem from "../Team/Teamitem";
import lang from "../../lang/lang";
const MeditationdataBookDetail = () => {
  return (
    <section className="experts_team_area sec_pad" style={{marginTop:"50px"}}>
      <div className="container">
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title={lang.S_MEDITATION}
          tClass="t_color3"
          TitleP=""
        />
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="Energy.jpg"
              memberN={lang.EnergyPurification}
              memberd={0+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="Release.jpg"
              memberN={lang.ReleaseBlockedEmotion}
              memberd={8+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="EnergyCultivation.jpg"
              memberN={lang.EnergyCultivation}
              memberd={8+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="HealingTheInnerChild.jpg"
              memberN={lang.HealingTheInnerChild}
              memberd={8+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="HealingRelationships.jpg"
              memberN={lang.HealingRelationships}
              memberd={8+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="ChakraBalancing.jpg"
              memberN={lang.ChakraBalancing}
              memberd={8+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="HealingRelationshipsMother.jpg"
              memberN={lang.HealingRelationshipsWithMother}
              memberd={8+ lang.P_Price}
            />
          </div>
          {/* <div className="col-lg-4 col-sm-6">
                        <Teamitem teamImage="team_8.jpg" memberN="Bailey Wonger" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Max Conversion" memberd="Social Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_10.jpg" memberN="Jake Weary" memberd="UI/UX designer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Justin Case" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_12.jpg" memberN="Norman Gordon" memberd="Web developer"/>
                    </div> */}
        </div>
      </div>
    </section>
  );
};
export default MeditationdataBookDetail;
