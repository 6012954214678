import React, { Component } from "react";
import Stserviceitems from "./StserviceItem";
import Reveal from "react-reveal/Reveal";
import lang from "../../../lang/lang";
class Sservice extends Component {
  render() {
    return (
      <section
        className="agency_service_area bg_color"
        style={{ marginTop: "50px" }}
      >
        <div className="container">
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center mb_90 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            {lang.C_ABOUTUS}
          </h2>
          <div className="row mb_30 ">
            <div className="row mt-5 mb-4">
              <div className="col-lg-6 col-sm-6">
                <h5>{lang.C_OURMESSAGE}</h5>
                <p>{lang.C_OURMESSAGEPARAGRAPH}</p>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="container">
                  <video
                    width="100%"
                    playsInline
                    loop
                    muted
                    controls
                    src="https://amanialotaibi.com/assets/amani_videos/%D9%81%D9%8A%D8%AF%D9%8A%D9%88%20%D8%AA%D8%B9%D8%B1%D9%8A%D9%81%D9%8A%20%D8%B9%D9%86%20%D8%A3%D9%85%D8%A7%D9%86%D9%8A.mp4"
                  ></video>
                </div>
              </div>
            </div>
            <div className="abouttext mt-5 mb-4">
              <h5>{lang.C_PROJECTGOAL}</h5>
              <ul>
                <li>{lang.C_PROJECTGOALFIRST}</li>
                <li>{lang.C_PROJECTGOALSECOND}</li>
                <li>{lang.C_PROJECTGOALTHIRD}</li>
              </ul>
            </div>
          </div>
          <h5 className="abouttext mt-5 mb-4">{lang.C_OURVISION}</h5>
          <p>{lang.C_OURVISIONPARAGRAPH}</p>
          <h5 className="abouttext mt-5 mb-4">{lang.C_MISSION}</h5>
          <p>{lang.C_MISSIONPARAGRAPH}</p>
        </div>
      </section>
    );
  }
}
export default Sservice;
