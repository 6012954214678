import React from "react";
import Sectitle from "../Title/Sectitle";
import Teamitem from "../Team/Teamitem";
import lang from "../../lang/lang";
const CouresBookDetail = () => {
  return (
    <section
      className="experts_team_area sec_pad"
      style={{ marginTop: "50px" }}
    >
      <div className="container">
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title={lang.S_COURSE}
          tClass="t_color3"
          TitleP=""
        />
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              // link="/EmotionalAwareness"
              teamImage="Emotional.jpg"
              memberN={lang.EmotionalAwareness}
              memberd={65+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="Man.jpg"
              memberN={lang.ManCodeLevel1}
              memberd={55+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="Attendance.jpg"
              memberN={lang.AttendanceChallenge}
              memberd={55+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="Chakras.jpg"
              memberN={lang.ChakrasDetox}
              memberd={35+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="Thankful.jpg"
              memberN={lang.ThankfulForRelationships}
              memberd={125+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="women.jpeg"
              memberN={lang.WOMENCODE}
              memberd={55+ lang.P_Price}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="dream.jpg"
              memberN={lang.Yourdreamrelationshipisreal}
              memberd={55+ lang.P_Price}
            />
          </div>
          {/* <div className="col-lg-4 col-sm-6">
                        <Teamitem
                          link="/"
                         teamImage="team_8.jpg" memberN="Bailey Wonger" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Max Conversion" memberd="Social Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_10.jpg" memberN="Jake Weary" memberd="UI/UX designer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Justin Case" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_12.jpg" memberN="Norman Gordon" memberd="Web developer"/>
                    </div> */}
        </div>
      </div>
    </section>
  );
};
export default CouresBookDetail;
