import React from 'react';
import { NavLink } from 'react-router-dom';


const SignInFrom =()=>{
    return(
        <section className="sign_in_area bg_color sec_pad"  style={{ marginTop: "50px" }}>
            <div className="container">
                <div className="sign_info">
                    <div className="row">
                        
                        <div className="col-lg-8">
                            <div className="login_info">
                                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Log In to Your Amani Account!</h2>
                                <form action="/#" className="login-form sign-in-form">
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Email</label>
                                        <input type="text" placeholder="Userame"/>
                                    </div>
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Password</label>
                                        <input type="password" placeholder="******"/>
                                    </div>
                                    <div className="extra mb_20">
                                        <div className="checkbox remember">
                                            <label>
                                                <input type="checkbox"/> Remember me
                                            </label>
                                        </div>
                                       
                                        <div className="forgotten-password">
                                            <a href="/#">Forgot Password?</a>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <button type="submit" className="btn_three">Login</button>
                                        <div className="social_text d-flex ">
                                            <div className="lead-text">Don't have an account? <NavLink to="/SignUp">SignUp</NavLink></div>
                                           
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SignInFrom;