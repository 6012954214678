import React from "react";
import Sectitle from "../Title/Sectitle";
import Teamitem from "../Team/Teamitem";
import lang from "../../lang/lang";
const PodastdataBookDetail = () => {
  return (
    <section className="experts_team_area sec_pad" style={{marginTop:"50px"}}>
      <div className="container">
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title={lang.S_POSCAST}
          tClass="t_color3"
          TitleP=""
        />
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.chakras}
              //   memberd="0 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.chakrasInDimensions}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.TheSevenMajorChakras}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.RootChakra}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.TheRootChakraPartTwo}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.Thankyounavel}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.Navelchakrainhealth}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.Thenavelchakrainrelationships}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.Solarplexuschakra}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.SolarPlexusChakraPartTwo}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.SolarPlexusChakraInRelationships}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.Femininityandmasculinityinthechakras}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.Femininityandmasculinityinthechakras}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.Femininityandmasculinityinthechakras}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.Thankfulheart}
              //   memberd="8 KWD"
            />
          </div>

          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.Throatchakrapartone}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.TheHeartChakraPartTwo}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.TheHeartChakraPartThree}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.TheThroatChakraPartTwo}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.Throatchakrapartthree}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.ThirdEyeChakraAjna}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.TheThirdEyeChakraAjnaPartTwo}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.TheThirdEyeChakraAjnaPartThree}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.CrownChakraTherelationshipofthecrown}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.CrownChakraPartTwo}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.TheCrownChakraPartThree}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.Importantnotesonthechakraspartone}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.ImportantPointsontheChakrasPartTwo}
              //   memberd="8 KWD"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Teamitem
              teamImage="PodcastImage.png"
              memberN={lang.ImportantPointsontheChakrasPartThree}
              //   memberd="8 KWD"
            />
          </div>

          {/* <div className="col-lg-4 col-sm-6">
                        <Teamitem teamImage="team_8.jpg" memberN="Bailey Wonger" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Max Conversion" memberd="Social Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_10.jpg" memberN="Jake Weary" memberd="UI/UX designer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Justin Case" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_12.jpg" memberN="Norman Gordon" memberd="Web developer"/>
                    </div> */}
        </div>
      </div>
    </section>
  );
};
export default PodastdataBookDetail;
