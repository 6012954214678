import React from "react";
import Sectitle from "../Title/Sectitle";
import BuyNowCouresIteam from "../Team/BuyNowCouresIteam";
const CouresBookDetail = () => {
  return (
    <section className="experts_team_area sec_pad"  style={{ marginTop: "50px" }}>
      <div className="container">
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title="Courses"
          tClass="t_color3"
          TitleP=""
        />
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <BuyNowCouresIteam
              teamImage="Emotional.jpg"
              memberN="Emotional Awareness"
              memberd="65 KWD"
            />
          </div>

          <div className="col-lg-6 col-sm-6">
            {/* <BuyNowCouresIteam
              link="/"
              teamImage="team_8.jpg"
              memberN="Bailey Wonger"
              memberd="Marketer"
            /> */}
            <div className="Heading mt-2">
              <h5>Healing</h5>
            </div>
            <div className="PricesCoures mt-4 mb-3">
              <h5> 75 KWD</h5>
            </div>
            <div className="TotalSession mt-4 mb-3">
              <h4>Total Session : 1</h4>
            </div>
            <div className="BuyNow">
              <button className="signup_btn btn_hover saas_banner_btn mt_40">
                Buy Now
              </button>
            </div>
            <div className="Description mt-4">
              <h5>Description</h5>
              <p className="mt-2 p-1">Healing</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CouresBookDetail;
